/* SplashScreen.css */
.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, #011549, #021C5E);
  /* background-color:linear-gradient(to top, #011549, #021C5E); */
  z-index: 1000;
  opacity: 1;
  transition: opacity 1s ease-out;
  /* Smooth fade-out for splash screen */
}

.splash-screen.hidden {
  opacity: 0;
  pointer-events: none;
  /* Prevent interaction when hidden */
  display: none;
  /* Completely remove it after fading out */
}

/* Text animation */
.splash-text {
  font-size: 2rem;
  color: #333;
  animation: fadeInOut 2.5s ease-in-out;
}

/* Keyframes for text fade-in and fade-out */
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(50px);
    /* Slight move from bottom */
  }

  30% {
    opacity: 50;
    transform: translateY(0);
    /* Normal position */
  }

  70% {
    opacity: 10;
  }

  100% {
    opacity: 100;
  }
}