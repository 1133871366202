@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-size: 12px;
}

body {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    background: var(--surface-ground);
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
    display: none;
}

.modal-box {
    overflow: visible;
}

.p-dialog-content {
    overflow: visible !important
}

div[role="menubar"][style*="top: 0px; left: 462px;"] {
    display: none !important;
}